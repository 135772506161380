<template>
  <div class="logs-page">
    <!-- Filter Section -->
    <div class="filters">
      <h4>Filters</h4>
      <form @submit.prevent="applyFilters">
        <div class="form-group">
          <label for="logId">Log ID:</label>
          <input
            type="text"
            id="logId"
            v-model="filters.logId"
            placeholder="Enter log ID or IDs separated by a comma"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="actionType">Action Type:</label>
          <select id="actionType" v-model="filters.actionType" class="form-control">
            <option value="">Please select</option>
            <option value="Connection">Connection</option>
            <option value="TPICompleted">TPICompleted</option>
          </select>
        </div>

        <div class="form-group">
          <label for="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            v-model="filters.firstName"
            placeholder="Enter first name"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="secondName">Second Name:</label>
          <input
            type="text"
            id="secondName"
            v-model="filters.secondName"
            placeholder="Enter second name"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="email">Email:</label>
          <input
            type="email"
            id="email"
            v-model="filters.email"
            placeholder="Enter email"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="countryCode">Country Code:</label>
          <input
            type="text"
            id="countryCode"
            v-model="filters.countryCode"
            placeholder="Enter country code"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="phone">Phone:</label>
          <input
            type="text"
            id="phone"
            v-model="filters.phone"
            placeholder="Enter phone number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            v-model="filters.startDate"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            v-model="filters.endDate"
            class="form-control"
          />
        </div>

        <button type="submit" class="btn btn-primary">Apply Filters</button>
      </form>
    </div>

    <!-- Logs Section -->
    <div class="logs">
      <h4>Logs</h4>
      <div v-if="filteredLogs.length > 0">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Log ID</th>
              <th>Action Type</th>
              <th>Timestamp</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in filteredLogs" :key="log.id">
              <td>{{ log.id }}</td>
              <td>{{ log.actionType }}</td>
              <td>{{ formatTimestamp(log.timestamp) }}</td>
              <td>{{ log.firstName || 'N/A' }}</td>
              <td>{{ log.secondName || 'N/A' }}</td>
              <td>
                <div v-if="log.actionType === 'TPICompleted' && log.data">
                  <table>
                    <tr>
                      <td>
                        <table>
                          <tr>
                            <td style="vertical-align: text-top;"><strong>TPI ID:</strong> {{ log.data?.tpi_id }}</td>
                            <td style="vertical-align: text-top;"><strong>Score:</strong> {{ log.data?.score }}</td>
                            </tr>
                            <tr>
                              <td style="vertical-align: text-top;"><strong>TPI:</strong> {{ log.data?.completed_tpi_cycle}}</td>
                              <td style="vertical-align: text-top;"><strong>Time:</strong>{{ log.data?.time_to_complete}}</td>
                            </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <a v-if="log.user_id" :href="`/users/profile/${log.user_id}`"
                          class="btn btn-secondary"
                          target="_blank"
                          rel="noopener noreferrer">
                          Go to Profile
                        </a>
                      </td>
                    </tr>
                </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>No logs found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";

export default {
  name: "LogsPage",
  data() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return {
      filters: {
        logId: "",
        actionType: "",
        firstName: "",
        secondName: "",
        email: "",
        countryCode: "",
        phone: "",
        startDate: today.toISOString().split("T")[0],
        endDate: tomorrow.toISOString().split("T")[0],
      },
      logs: [],
      errorMessage: "",
    };
  },
  computed: {
    filteredLogs() {
      return this.logs.filter(log => {
        // Apply all filters based on user input
        if (
          (this.filters.logId && !log.id.toString().includes(this.filters.logId)) ||
          (this.filters.actionType && log.actionType !== this.filters.actionType) ||
          (this.filters.firstName && log.firstName !== this.filters.firstName) ||
          (this.filters.secondName && log.secondName !== this.filters.secondName) ||
          (this.filters.email && log.email !== this.filters.email) ||
          (this.filters.countryCode && log.countryCode !== this.filters.countryCode) ||
          (this.filters.phone && log.phone !== this.filters.phone)
        ) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      if (!timestamp) return 'N/A';
      const date = new Date(timestamp);
      return date.toISOString().slice(0, 19).replace('T', ' ');
    },
    async applyFilters() {
      try {
        const token = getToken();
        if (!token) {
          this.$router.push("/login");
          return;
        }

        const payload = { ...this.filters };
        const response = await requests.get("logs/filtered", payload, token);
        console.log(response.data);

        if (response && response.data) {
          this.logs = response.data.logs.map(log => this.formatLogData(log));
        } else {
          this.logs = [];
          this.errorMessage = "No logs found for the applied filters.";
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
        this.logs = [];
        this.errorMessage =
          "An error occurred while fetching logs. Please try again later.";
      }
    },
    formatLogData(log) {
      return log; // No further processing needed if the API data is already correct
    },
  },
  mounted() {
    this.applyFilters(); // Fetch logs on initialization
  },
};
</script>

<style scoped>
.logs-page {
  display: flex;
  gap: 20px;
  padding: 5px;
}

.filters {
  flex: 1;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.logs {
  flex: 3;
  border: 1px solid #ddd;
  padding: 2px;
  border-radius: 8px;
  background-color: #fff;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 2px;
  text-align: left;
}

.btn {
  margin-top: 2px;
  display: inline-block;
  padding: 2px 20px;
  background-color: #007bff;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}
</style>
