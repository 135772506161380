<template>
  <div class="cycle-selector">
    <!-- New Cycle Button -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <button class="btn btn-success" @click="handleCycleCreation">
        New Cycle
      </button>
    </div>

    <h4>Cycle Selector</h4>

    <!-- Cycle Dropdown -->
    <div v-if="cycles.length">
      <div class="mb-3">
        <label for="cycleSelector" class="form-label">Select a Cycle:</label>
        <select
          id="cycleSelector"
          class="form-select"
          v-model="selectedCycleId"
          @change="fetchCycleDetails"
        >
          <option
            v-for="(cycle, index) in cycles"
            :key="cycle.tpi_cycle_id"
            :value="cycle.tpi_cycle_id"
          >
            Cycle {{ index + 1 }}: Created at
            {{ new Date(cycle.started_at).toLocaleString() }} -
            {{ cycle.is_completed ? 'Completed' : 'In Progress' }}
          </option>
        </select>
      </div>

      <!-- Display selected cycle details -->
      <div v-if="selectedCycleDetails">
        <h5>Cycle Details</h5>
        <p><strong>Pad ID:</strong> {{ selectedCycleDetails.pad_id }}</p>
        <p><strong>Started At:</strong> {{ selectedCycleDetails.started_at }}</p>
        <p><strong>Is Completed:</strong> {{ selectedCycleDetails.is_completed }}</p>

        <!-- Pass userId and selectedCycleId to TpiSelector -->
        <TpiSelector
          v-if="selectedCycleDetails"
          :id="userId"
          :cycle-id="selectedCycleId"
          :key="selectedCycleId"
        />
      </div>
    </div>

    <div v-else>
      <p>No cycles available for this user.</p>
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";
import TpiSelector from "@/components/user/TPISelector.vue";

export default {
  name: "CycleSelector",
  components: {
    TpiSelector,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const cycles = ref([]);
    const selectedCycleId = ref(null);
    const selectedCycleDetails = ref(null);
    const errorMessage = ref("");

    // Fetch cycles for the user
    const fetchCycles = async () => {
      try {
        const response = await requests.get(`/cycle/user/${props.userId}`, {}, getToken());
        if (response && response.data) {
          const { current_cycle, pads } = response.data;
          cycles.value = Object.values(pads)
            .flat()
            .sort((a, b) => new Date(a.started_at) - new Date(b.started_at));

          if (current_cycle) {
            selectedCycleId.value = current_cycle.tpi_cycle_id;
            fetchCycleDetails();
          }
        } else {
          throw new Error("Failed to fetch cycles.");
        }
      } catch (error) {
        errorMessage.value = "An error occurred while fetching cycles.";
        console.error(error);
      }
    };

    // Fetch details for the selected cycle
    const fetchCycleDetails = async () => {
      if (!selectedCycleId.value) {
        selectedCycleDetails.value = null; // Clear previous details
        return;
      }

      try {
        const response = await requests.get(
          `/cycle/user/${props.userId}/cycle/${selectedCycleId.value}`,
          {},
          getToken()
        );
        if (response && response.data) {
          selectedCycleDetails.value = response.data.cycle;
        } else {
          throw new Error("Failed to fetch cycle details.");
        }
      } catch (error) {
        errorMessage.value = "An error occurred while fetching cycle details.";
        console.error(error);
      }
    };

    // Handle the creation of a new cycle with a check for current cycle completion
    const handleCycleCreation = async () => {
      try {
        const macroResponse = await requests.get(`/tpi/macro/user/${props.userId}`, {}, getToken());
        if (macroResponse && macroResponse.data) {
          const currentCycleData = macroResponse.data;

          if (!currentCycleData.is_completed) {
            const confirmed = confirm(
              "The current cycle is not completed. Do you want to create a new cycle anyway?"
            );
            if (!confirmed) return;
          }

          await createNewCycle();
        } else {
          throw new Error("Failed to check the current cycle status.");
        }
      } catch (error) {
        errorMessage.value = "An error occurred while checking the cycle status.";
        console.error(error);
      }
    };

    // Create a new cycle
    const createNewCycle = async () => {
      try {
        const response = await requests.post(`/cycle/${props.userId}`, {}, getToken());
        if (response && response.status === 201) {
          alert("New cycle created successfully!");
          fetchCycles();
        } else {
          throw new Error("Failed to create a new cycle.");
        }
      } catch (error) {
        errorMessage.value = "An error occurred while creating a new cycle.";
        console.error(error);
      }
    };

    // Watch for changes in selectedCycleId and update details
    watch(selectedCycleId, (newValue) => {
      if (newValue) {
        fetchCycleDetails();
      }
    });

    onMounted(() => {
      fetchCycles();
    });

    return {
      cycles,
      selectedCycleId,
      selectedCycleDetails,
      errorMessage,
      fetchCycles,
      fetchCycleDetails,
      handleCycleCreation,
    };
  },
};
</script>

<style scoped>
.cycle-selector {
  margin-top: 20px;
}

.text-danger {
  color: #dc3545;
}
</style>
